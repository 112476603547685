import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { grey, indigo } from "@mui/material/colors";

export const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(indigo[500]),
  backgroundColor: "#E565C8",
  fontFamily: "nunito",
  textTransform: "none",
  fontSize: "16px",
  shadows: "none",
  fontWeight: 500,
  marginTop: 20,
  width: "210px",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: indigo[700],
  },
}));

export const ColorButton2 = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(indigo[500]),
  backgroundColor: "#E565C8",
  fontFamily: "nunito",
  textTransform: "none",
  fontSize: "16px",
  shadows: "none",
  fontWeight: 500,
  marginTop: 20,
  width: "100%",
  borderRadius: 0,
  "&:hover": {
    backgroundColor: indigo[700],
  },
}));

export const GreyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[200]),
  backgroundColor: grey[50],
  fontFamily: "nunito",
  textTransform: "none",
  fontSize: "18px",
  fontWeight: 500,
  marginTop: 20,
  width: 250,
  "&:hover": {
    backgroundColor: grey[400],
  },
}));

export const Community =
  "https://join.slack.com/t/mlopsinstitute/shared_invite/zt-1b1zwozep-oR6TiuO6~7i0e1UY7cappg";
export const Github =
  "https://github.com/Rocket-Science-Development/pulsar_demo";
export const Linkedin = "https://www.linkedin.com/company/pulsar-ml";
export const Discord = "https://discord.gg/23V98YD6ts";
export const Docs =
  "https://github.com/Rocket-Science-Development/pulsar_demo#readme";
export const RocketScience = "https://rocketscience.one";
