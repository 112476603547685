import React from "react";
import { Link } from "@mui/material";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { ColorButton, Community, Github } from "../../constants";
import "./Help.css";

export default function Help() {
  return (
    <div className="container">
      <div className="wrapper-help-section1">
        <h2 className="h2-title-white">Help Center</h2>
        <div className="row-container">
          <div className="left-container">
            <div>
              <span className="h2-title-blue">Getting </span>
              <span className="h2-title-purple">started</span>
            </div>
            <p className="p-bold">
              Everything you need to get up and running on Pulsar.
            </p>

            <div className="row">
              <h3 className="h3-title-ltpurple">Pulsar quick-start guide</h3>
              <Link
                href={Github}
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                rel="noreferrer noopener"
              >
                <IoIosArrowDroprightCircle
                  style={{ fontSize: "3rem", color: "#E565C8" }}
                  className="help-pulsar-quick-start-guide"
                ></IoIosArrowDroprightCircle>
              </Link>
            </div>

            <div className="row">
              <h3 className="h3-title-ltpurple">Create Pulses</h3>
              <Link
                href={Github}
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                rel="noreferrer noopener"
              >
                <IoIosArrowDroprightCircle
                  style={{ fontSize: "3rem", color: "#E565C8" }}
                  className="help-create-pulses"
                ></IoIosArrowDroprightCircle>
              </Link>
            </div>

            <div className="row">
              <h3 className="h3-title-ltpurple">
                Understanding model monitoring
              </h3>
              <Link
                href={Github}
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                rel="noreferrer noopener"
              >
                <IoIosArrowDroprightCircle
                  style={{ fontSize: "3rem", color: "#E565C8" }}
                  className="help-understanding"
                ></IoIosArrowDroprightCircle>
              </Link>
            </div>

            <div className="row">
              <h3 className="h3-title-ltpurple">Manage your Pulses</h3>
              <Link
                href={Github}
                style={{ textDecoration: "none", color: "black" }}
                target="_blank"
                rel="noreferrer noopener"
              >
                <IoIosArrowDroprightCircle
                  style={{ fontSize: "3rem", color: "#E565C8" }}
                  className="help-manage-pulses"
                ></IoIosArrowDroprightCircle>
              </Link>
            </div>
          </div>
          <div className="right-container">
            <img
              className="datamodel-container"
              src={require("../../gif_datamodel.gif")}
              alt="pulsar"
            />
          </div>
        </div>
      </div>

      <div className="wrapper-help-section2">
        <div className="row-section2">
          <div>
            <h3 className="h3-title-ltpurple">Need more help?</h3>
            <p>
              Contact Support
              <br />
              Tell us about your problem, and we’ll find you a solution.
            </p>
            <Link
              href="/hire-an-expert"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <ColorButton variant="contained" className="help-get-help">
                Get Help
              </ColorButton>
            </Link>
          </div>
          <div>
            <h3 className="h3-title-ltpurple">Hire an Expert</h3>
            <p>
              Pulsar Experts are professional machine learning engineers who
              specialize in model monitoring.
            </p>
            <Link
              href="/hire-an-expert"
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <ColorButton variant="contained" className="help-hire">
                Hire an Expert
              </ColorButton>
            </Link>
          </div>
          <div>
            <h3 className="h3-title-ltpurple">Pulsar Community</h3>
            <p>
              Connect with other Pulsar users and industry professionals to help
              you monitor your models in production.
            </p>
            <Link
              href={Community}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noreferrer noopener"
            >
              <ColorButton
                variant="contained"
                style={{ width: 290 }}
                className="help-check-community"
              >
                Check out the Community
              </ColorButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
