import React, { useState } from "react";
import {
  Drawer,
  Link,
  List,
  AppBar,
  Toolbar,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Github, RocketScience } from "../../constants";
import "./Navbar.css";

export default function Navbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <div className="navigation-bar">
      <Link href="/" style={{ textDecoration: "none", color: "black" }}>
        <img
          className="pulsar-logo"
          src="../logo-pulsar.png"
          alt="Pulsar logo"
        />
      </Link>
      <div className="topright-nav">
        <div>
          <p className="p-nav">powered by</p>
        </div>
        <Link
          href={RocketScience}
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="logo-nav">
            <img
              className="RS-logo"
              src="../logo-RS.png"
              alt="Rocket Science logo"
            />
          </div>
        </Link>
      </div>

      <div className="menu">
        <ul className="menu-bar">
          <a href="/">Home</a>
          <a href="/how-it-works">How it Works</a>
          <a href="/features">Features</a>
          <a href="/our-experts">Our Experts</a>
          <a href="/help-centre">Help Center</a>
          <a href={Github} target="_blank" rel="noreferrer noopener">
            Community
          </a>
          <a href="/news">News</a>
          <a href="/hire-an-expert" target="_blank" rel="noreferrer noopener">
            Book a Demo
          </a>
        </ul>
      </div>

      <div className="menu-mobile">
        <AppBar
          variant="outlined"
          position="static"
          style={{
            background: "transparent",
            boxShadow: "none",
            border: "none",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              open={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              sx={{ mt: 200 }}
              anchor="right"
            >
              <List>
                <Link
                  href="/"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="Home" />
                  </ListItem>
                </Link>

                <Link
                  href="/how-it-works"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="How it Works" />
                  </ListItem>
                </Link>

                <Link
                  href="/features"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="Features" />
                  </ListItem>
                </Link>

                <Link
                  href="/our-experts"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="Our Experts" />
                  </ListItem>
                </Link>

                <Link
                  href="/help-centre"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="Help Centre" />
                  </ListItem>
                </Link>

                <Link
                  href={Github}
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <ListItem button>
                    <ListItemText primary="Community" />
                  </ListItem>
                </Link>

                <Link
                  href="/news"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="News" />
                  </ListItem>
                </Link>

                <Link
                  href="/hire-an-expert"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <ListItem button>
                    <ListItemText primary="Book a Demo" />
                  </ListItem>
                </Link>
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}
