import React from "react";
import Typical from "react-typical";
import { Link } from "@mui/material";
import { ColorButton, ColorButton2, RocketScience } from "../../constants";
import "./Home.css";

export default function Home() {
  return (
    <div className="container">
      <div
        className="wrapper-section1"
        style={{ backgroundImage: "url(./web.png)" }}
      >
        <div className="left-section1">
          <h1 className="h1-title-a">Monitor your</h1>
          <Typical
            steps={[
              "machine learning",
              1500,
              "data drift",
              1500,
              "artificial intelligence",
              2000,
              "analytical assets",
              1500,
              "concept drift",
              1500,
              "model drift",
              1500,
              "model performance",
              1500,
            ]}
            loop={Infinity}
            wrapper="p"
            className="h1-title-b"
          />
          <p className="paragraph">
            Pulsar empowers you to monitor your machine learning models in
            production - so you can get the best out of your models.
          </p>
          <Link
            href="/hire-an-expert"
            style={{ textDecoration: "none", color: "black" }}
            target="_blank"
            rel="noreferrer noopener"
          >
            <ColorButton
              variant="contained"
              className="home-get-started"
              disableElevation={true}
            >
              Book a Demo
            </ColorButton>
          </Link>
        </div>
        <video width="750" height="500" autoPlay muted loop>
          <source src="../gif_dashboard_BP.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="wrapper-section2">
        <div className="left-section2">
          <h2 className="h2-title-a">Powerful insights,</h2>
          <h2 className="h2-title-c">unmatched machine learning</h2>
          <p className="paragraph">
            Monitoring for all
            <br />
            From casual coders to machine learning leads, Pulsar monitors your
            machine learning pipeline in production so you can mitigate all
            drift.
            <br />
            <br />
            Software Engineers
            <br />
            Integrates easily with any existing production systems at the code
            level.
            <br />
            <br />
            Data Scientists
            <br />
            Capture relevant insights about your machine learning model
            performance in production
            <br />
            <br />
            Machine Learning Engineers
            <br />
            Monitor in real-time your machine learning assets
          </p>

          <Link
            href="/hire-an-expert"
            style={{ textDecoration: "none", color: "black" }}
            target="_blank"
            rel="noreferrer noopener"
          >
            <ColorButton
              variant="contained"
              className="home-get-started"
              disableElevation={true}
            >
              Book a Demo
            </ColorButton>
          </Link>
        </div>
        <div className="column-section2">
          <img
            style={{ width: "80%", marginTop: "50px" }}
            src={require("../../gif_notebook.gif")}
            alt="pulsar"
          />
          <img
            style={{ width: "80%", marginTop: "50px" }}
            src={require("../../gif_datamodel.gif")}
            alt="pulsar"
          />
        </div>
      </div>

      <div className="wrapper-section3">
        <div className="left-section3">
          <img
            className="right-section3"
            style={{ width: "110%", marginTop: "10px" }}
            src="./img-dashboard-BP.png"
            alt="pulsar dashboard"
          />
        </div>
        <div className="right-section3">
          <h2 className="h2-title-a">100% community-edition, </h2>
          <h2 className="h2-title-c">best monitoring practice</h2>
          <p className="paragraph">
            Real-time Monitoring <br />
            Monitoring in real time your machine learning models in production
            from multiple systems.
            <br />
            <br />
            Automatic Analysis
            <br />
            Capture the performance of your machine learning model at multiple
            performance levels.
            <br />
            <br />
            Production Data Storage
            <br />
            Store your machine learning production data in a safe and reliable
            environment.
          </p>
          <Link
            href="/features"
            style={{ textDecoration: "none", color: "black" }}
          >
            <ColorButton
              variant="contained"
              className="home-get-started"
              disableElevation={true}
            >
              Discover more features
            </ColorButton>
          </Link>
        </div>
      </div>

      <div className="wrapper-section4">
        <div>
          <span className="h2-title-a">Created by </span>
          <span className="h2-title-b">Industry Experts</span>
        </div>
        <p className="p-centered">
          Pulsar was envisioned by a team of machine learning engineers who
          believed there <br />
          was a better way to monitor your models in production.
        </p>
        <Link
          href="/hire-an-expert"
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <ColorButton2
            variant="contained"
            className="home-get-started"
            disableElevation={true}
          >
            Book a meeting with our experts
          </ColorButton2>
        </Link>
      </div>

      <div className="wrapper-section5">
        <div className="box-section5">
          <div>
            <span className="h2-title-a">Supported </span>
            <span className="h2-title-c">Database Integration</span>
          </div>
          <img
            className="logo40"
            src="./logo-influxdb.png"
            alt="Influxdb logo"
          />
        </div>
      </div>

      <div className="wrapper-section6">
        <div className="box-section5">
          <div>
            <span className="h2-title-a">Guidelines for </span>
            <span className="h2-title-c">Deployment integration</span>
          </div>
          <div className="white">
            <div className="column">
              <div className="row responsive-row">
                <img
                  className="logo15"
                  src="./logo-flask.png"
                  alt="flask logo"
                />
                <img
                  className="logo25"
                  src="./logo-fastapi.png"
                  alt="fast api logo"
                />
                <img
                  className="logo25"
                  src="./logo-tensorflowext.png"
                  alt="fast api logo"
                />
              </div>
              <div className="row responsive-row">
                <img
                  className="logo25"
                  src="./logo-seldon.png"
                  alt="tensorflow extended logo"
                />
                <img className="logo20" src="./logo-aws.png" alt="aws logo" />
                <img
                  className="logo30"
                  src="./logo-azure.png"
                  alt="azure logo"
                />
              </div>
              <div className="row responsive-row">
                <img
                  className="logo25"
                  src="./logo-googlecloud.png"  
                  alt="gcp logo"
                />
                <img
                  className="logo25"
                  src="./logo-grafana.png"
                  alt="grafana logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper-section7">
        <div>
          <span className="h2-title-a">Powered by </span>
          <span className="h2-title-b">Rocket Science</span>
        </div>
        <Link
          href={RocketScience}
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: "none" }}
        >
          <ColorButton2
            variant="contained"
            className="home-get-started"
            disableElevation={true}
          >
            See more
          </ColorButton2>
        </Link>
      </div>
    </div>
  );
}
