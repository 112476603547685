import React from "react";
import "./Calendly.css";

export default function Calendly() {
  return (
    <div className="wrapper-hire-section1">
      <h2 className="h2-title-white">Book a Demo</h2>
      <iframe
        src="https://calendly.com/rocketscience1/60min?month=2023-06"
        width="100%"
        height="800px"
        title="Calendly Page"
      ></iframe>
    </div>
  );
}
