import "./App.css";
import { Route, Routes } from "react-router-dom";
import News from "./components/News/News";
import Home from "./components/Home/Home";
import Help from "./components/Help/Help";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Experts from "./components/Experts/Experts";
import Feature from "./components/Feature/Feature";
import Calendly from "./components/Calendly/Calendly";
import NavbarMain from "./components/Navbar/NavbarMain";
import Howitworks from "./components/Howitworks/Howitworks";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <>
              <NavbarMain /> <Home />
            </>
          }
        />
        <Route
          path="/how-it-works"
          element={
            <>
              <Navbar /> <Howitworks />
            </>
          }
        />
        <Route
          path="/features"
          element={
            <>
              <Navbar /> <Feature />
            </>
          }
        />
        <Route
          path="/news"
          element={
            <>
              <Navbar />
              <News />
            </>
          }
        />
        <Route
          path="/our-experts"
          element={
            <>
              <Navbar />
              <Experts />
            </>
          }
        />
        <Route
          path="/help-centre"
          element={
            <>
              <Navbar />
              <Help />
            </>
          }
        />
        <Route
          path="/hire-an-expert"
          element={
            <>
              <Navbar />
              <Calendly />
            </>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
