import React from "react";
import { Link } from "@mui/material";
import { ColorButton2 } from "../../constants";
import "./News.css";

export default function News() {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "./article1.pdf"; // Replace with the actual file source
    link.download = "Introduction to Machine Learning Observability.pdf"; // Replace with the desired file name and extension
    link.click();
  };

  const handleDownload2 = () => {
    const link = document.createElement("a");
    link.href = "./article2.pdf"; // Replace with the actual file source
    link.download = "Drift detection metrics and performance metrics.pdf"; // Replace with the desired file name and extension
    link.click();
  };

  return (
    <div>
      <div className="wrapper-news-section1">
        <h2 className="h2-title-white">News</h2>
        <div className="container-news">
          <div className="column-news">
            <h3 className="h3-title-news">
              Drift Detection Metrics and Performance Metrics
            </h3>
            <p>07/24/2023</p>
            <p className="p-news">
              Drift detection metrics and performance metrics are two essential
              concepts in the field of machine learning, particularly in
              production environments where models are continuously updated and
              deployed.Drift detection metrics refer to the process of
              monitoring the performance of a deployed model over time and
              detecting any changes in its behavior. Drift detection can help
              identify situations where the data distribution has changed, or
              the model's accuracy has deteriorated, leading to a potential need
              for retraining the model. Some commonly used drift detection
              metrics include accuracy, precision, recall, and F1 score.
            </p>
          </div>
          <div className="img-container">
            <img className="img-news" src="./article2.png" alt="news" />
            <p className="p-download" onClick={handleDownload2}>
              click to download
            </p>
          </div>
        </div>

        <div className="container-news">
          <div className="column-news">
            <h3 className="h3-title-news">
              Introduction to Machine Learning Observability
            </h3>
            <p>05/03/2023</p>
            <p className="p-news">
              In the contemporary world, we interact with Artificial
              Intelligence (AI) in some form or another every day. On the other
              hand, businesses are progressively using machine learning (ML)
              models, the most common type of AI, to improve their operational
              efficiency and decision-making ability. However, deploying ML
              models in business operations presents many technical challenges,
              such as performance, accuracy, and scalability. These ML models
              now support use cases in many forms and modalities (tables, time
              series, text, image, video and audio). Furthermore, these models
              handle vast amounts of data, which can be either delay-sensitive
              or reliable sensitive, to deploy in a cloud or distributed
              platform. This mode of deployment and execution adds complexity to
              the system, which can negatively impact its performance.
            </p>
          </div>
          <div className="img-container">
            <img className="img-news" src="./article1.png" alt="news" />
            <p className="p-download" onClick={handleDownload}>
              click to download
            </p>
          </div>
        </div>
      </div>

      <div className="wrapper-news-section2">
        <h1 className="h1">See what monitoring can do for you</h1>
        <Link
          href="/hire-an-expert"
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <ColorButton2 variant="contained" className="newss-get-started">
            Book a Demo
          </ColorButton2>
        </Link>
      </div>
    </div>
  );
}
