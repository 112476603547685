import React from "react";
import { Link } from "@mui/material";
import { ColorButton2, Github } from "../../constants";
import "./Howitworks.css";

export default function Howitworks() {
  return (
    <div className="container-hiw">
      <div className="wrapper-hiw-section1">
        <h2 className="h2-title-white">How it works</h2>
        <div className="column">
          <div className="row-hiw">
            <div className="left-column">
              <img className="asset-number" src="../asset-1.png" alt="1" />
              <img
                className="asset"
                src="../asset-arrow.png"
                alt="finger moving from left to the right"
              />
              <h3 className="h3-title">Start with instantiating your assets</h3>
              <p className="p-steps">Create the database, upload your data.</p>
            </div>
            <div className="right-column">
              <img className="asset-number" src="../asset-2.png" alt="2" />
              <img
                className="asset"
                src="../asset-move.png"
                alt="hand moving box left to the right"
              />
              <h3 className="h3-title">
                Next, inject Pulsar into your production code
              </h3>
              <p className="p-steps">Import Pulsar and collect the data.</p>
            </div>
          </div>
          <div className="row-hiw">
            <div className="left-column">
              <img className="asset-number" src="../asset-3.png" alt="3" />
              <img
                className="asset"
                src="../asset-magic.png"
                alt="magic wand"
              />
              <h3 className="h3-title">This is where the magic happens</h3>
              <p className="p-steps">
                Pulsar structures your production data and merges it with
                offline data followed by the Pulsar metric analyzes your
                data.
              </p>
            </div>
            <div className="right-column">
              <img className="asset-number" src="../asset-4.png" alt="4" />
              <img
                className="asset"
                src="../asset-start.png"
                alt="finger pressing start button"
              />
              <h3 className="h3-title">
                And that’s what we call a Pulse (Pulse is the output)
              </h3>
              <p className="p-steps">
                Run the Pulsar analyzer on your notebook and get insights around
                your machine learning model.
              </p>
            </div>
          </div>
          <img className="asset-number" src="../asset-5.png" alt="5" />
          <img
            className="asset"
            src="../asset-bulb.png"
            alt="hand holding bulb"
          />
          <h3 className="h3-title">Pulses give you more insights</h3>
          <div className="gif-wrapper">
            <img
              className="gif"
              style={{ width: "80%" }}
              src={require("../../gif_datamodel.gif")}
              alt="pulsar"
            />
          </div>
        </div>
      </div>

      <div className="wrapper-hiw-section2">
        <h2 className="h2-title-blue">FAQ</h2>
        <div>
          <h3 className="h3-title">What is Pulsar?</h3>
          <p>
            Pulsar is model performance and management (MPM) software solution.
            This solution allow users to monitor their data inputs and model
            outputs and their evolution over time, across various drift and
            other statistical features. A core functionality of ‘alerts’ will
            allow automatic monitoring to relevant stakeholders when various
            performance metrics reach important thresholds.
          </p>
          <h3 className="h3-title">
            How does Pulsar monitor my machine learning models?
          </h3>
          <p>
            Pulsar provides an easily integrated plug-n-play containerised
            service which provides different features like Data Ingestion,
            Metric calculations, Display feature metrics, Custom alert
            functionality. Data collected with the data-ingestion service can be
            used to calculate different metrics (data drift and performance)
            using user's training (baseline) dataset for different
            time-intervals. Based on the same, Pulsar will use its scheduler
            service to monitor drift and trigger different standard and
            customised alerts.
          </p>
          <h3 className="h3-title">What platforms can I connect to Pulsar?</h3>
          <p>
            Pulsar is being developed currently to augment different
            marketplaces (Azure, AWS) for the product to be available for users
            and be used along with other standard services to further enrichen
            the end-user experience.
          </p>
          <h3 className="h3-title">What metrics do you support?</h3>
          <p>Data Drift:</p>
          <ul>
            <li> Kullback-Leibler divergence</li>
            <li> Kolmogorov-Smirnov statistic</li>
            <li> Levene Data Drift</li>
            <li> Man-Wu Data Drift</li>
            <li> BF Test Data Drift</li>
            <li> TTest Data Drift</li>
            <li> Wasserstein Data Drift</li>
            <li> CVM Data Drift</li>
          </ul>
          <p>Peformance:</p>
          <ul>
            <li> Accuracy</li>
            <li> Recall</li>
            <li> F1 score</li>
            <li> Brier score</li>
            <li> Mean Squared Error</li>
            <li> Mean Absolute Percentage Error</li>
            <li> R2 score</li>
            <li> Log-loss</li>
            <li> Area Under Curve</li>
            <li> Area Under Curve (Positivity Rate)</li>
          </ul>
        </div>
      </div>

      <div className="wrapper-hiw-section3">
        <h2 className="h2-title-blue">
          You didn't find what you were looking for?
        </h2>
        <Link
          href="/hire-an-expert"
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <ColorButton2 variant="contained" className="howitworks-get-started">
            Contact us
          </ColorButton2>
        </Link>
      </div>
    </div>
  );
}
