import React from "react";
import { Link } from "@mui/material";
import { ColorButton, Github } from "../../constants";
import "./Experts.css";

export default function Experts() {
  return (
    <div className="wrapper-experts-section1">
      <div>
      <h2 className="h2-title-white">Our Experts</h2>
      <div>
        <span className="h2-title-blue">Hire a </span>
        <span className="h2-title-purple">Pulsar Expert</span>
      </div>
      <p className="paragraph">
        Want to get more out of Pulsar? Pulsar Experts are professional machine
        learning engineers who specialize in model monitoring. <br />
        <br />
        Whether you need help learning how to get the most of of Pulsar, or need
        help building your machine learning pipeline, you’ll find the right fit
        here.
      </p>
      <Link
        href="/hire-an-expert"
        style={{ textDecoration: "none", color: "black" }}
        target="_blank"
        rel="noreferrer noopener"
      >
        <ColorButton
          variant="contained"
          className="home-get-started"
          disableElevation={true}
        >
          Hire an Expert
        </ColorButton>
      </Link>
      </div>
      <div className="wrapper-team">
        <div>
          <span className="h2-title-blue">Only </span>
          <span className="h2-title-purple">Legends </span>
          <span className="h2-title-blue">Here </span>
        </div>
        <div className="row-pic">
          <div>
            <img className="image" src="../img-mohamed.png" alt="Mohamed" />
            <h2>Mohamed Sabri</h2>
            <p>CEO & Co-Founder</p>
          </div>
          <div>
            <img className="image" src="../img-eric.png" alt="Eric" />
            <h2>Eric Hammel</h2>
            <p>Machine Learning Engineer</p>
          </div>
          <div>
            <img className="image" src="../img-adel.png" alt="Adel" />
            <h2>Adel Bengalara</h2>
            <p>Data Science Advisor</p>
          </div>
        </div>
        <div className="row-pic">
          <div>
            <img className="image" src="../img-manu.png" alt="Manu" />
            <h2>Manu Arya</h2>
            <p>Software Engineer</p>
          </div>
          <div>
            <img className="image" src="../img-asta.png" alt="Asta" />
            <h2>Astarina Maulida</h2>
            <p>Software Engineer</p>
          </div>
          <div>
            <img className="image" src="../img-dmytro.png" alt="Dmytro" />
            <h2>Dmytro Nasikan</h2>
            <p>Software Engineer</p>
          </div>
        </div>
      </div>
    </div>
  );
}
