import React from "react";
import { Link } from "@mui/material";
import { ColorButton2 } from "../../constants";
import "./Feature.css";

export default function Feature() {
  return (
    <div>
      <div className="wrapper-ft-section1">
        <h2 className="h2-title-white">Features</h2>
        <div className="column-feature">
          <h3 className="h3-title-feature">Import Pulsar and ingest data</h3>
          <p className="p-feature">
            <ul className="column-feature">
              <li>
                Pulsar allows the end users to ingest their model data (both
                input and output) in a Time series database. <br />
                At the moment, our integration works with InfluxDB which would
                be expanded to support other time series databases in near
                future.
              </li>
              <li>
                Pulsar supports both historical and real-time data to be used
                for different metric calculations.
              </li>
              <li>
                Scalable built of Pulsar makes sure that performance is not
                degraded with huge volumes of ingested data.
              </li>
              <li>
                Pulsar SDK and Data Ingestion containerised service are easily
                integrated to end user's machine.
              </li>
            </ul>
          </p>
          <h3 className="h3-title-feature">
            Data Drift and Performance Metrics
          </h3>
          <p className="p-feature">
            <ul>
              <li>
                Pulsar employs various industry relevant and standard metrics
                for users to be able to detect data and model drift.
              </li>
              <li>
                Pulsar would additionally provide feature store update for users
                to update ground truth over historic data.
              </li>
            </ul>
          </p>
          <h3 className="h3-title-feature">
            Dashboard for Metric visualisation
          </h3>
          <p className="p-feature">
            Pulsar is integrated with an inbuilt Grafana service for displaying
            various data drift metrics over different time-ranges for all the
            user-defined features.
          </p>
        </div>
      </div>

      <div className="wrapper-ft-section2">
        <h1 className="h1">See what monitoring can do for you</h1>
        <Link
          href="/hire-an-expert"
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <ColorButton2 variant="contained" className="features-get-started">
            Book a Demo
          </ColorButton2>
        </Link>
      </div>

      <div className="wrapper-ft-section3">
        <h1 className="h1-title-feature">Pulsar in action</h1>
        <p>
          See how Pulsar can empower you to monitor your models in production.
        </p>
        <video width="750" height="500" autoPlay muted loop>
          <source src="../gif_dashboard_BP.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
}
