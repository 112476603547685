import React from "react";
import { Link } from "@mui/material";
import { Github, Discord, Linkedin } from "../../constants";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="wrapper-footer">
      <div className="left-footer">
        <p className="p-footer">
          © Copyright 2022. All rights reserved by Pulsar
        </p>
      </div>
      <div className="right-footer">
        <Link
          href={Github}
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="icon-footer"
            src="./icon-github.png"
            alt="github icon"
          />
        </Link>
        <Link
          href={Discord}
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="icon-footer"
            src="./icon-discord.png"
            alt="discord icon"
          />
        </Link>
        <Link
          href={Linkedin}
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="icon-footer"
            src="./icon-linkedin.png"
            alt="linkedin icon"
          />
        </Link>
        <a
          href="mailto:info@pulsar.ml"
          style={{ textDecoration: "none", color: "black" }}
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            className="icon-footer"
            src="./icon-email.png"
            alt="email icon"
          />
        </a>
      </div>
    </div>
  );
}
